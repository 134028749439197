import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useDisconnect } from '@web3modal/ethers5/react';
import { BrowserProvider, Contract, formatUnits, ethers } from 'ethers';
import { useState, useEffect, useCallback } from 'react';

const projectId = '1f6ccc6c4aa6e6d94031084bd211b38e';

const mainnet = {
  chainId: 42161,
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrl: 'https://arbiscan.io',
  rpcUrl: 'https://arbitrum-mainnet.infura.io/v3/674f0534928b4310ac4c8722123a5f5b',
};

const metadata = {
  name: 'Meny',
  description: 'Meny dApp',
  url: 'https://menycoin.com',
  icons: ['https://menycoin.com/favicon.png'],
};

const ethersConfig = defaultConfig({
  metadata,
  defaultChainId: 42161,
  enableEIP6963: true,
  rpcUrl: 'https://fabled-distinguished-owl.arbitrum-mainnet.quiknode.pro/546d1ee6bb9e4fb897c95135b1ec4ba3a73794c9',
});

const bnbMainnet = {
  chainId: 56,
  name: 'Binance Smart Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/',
};

const ethersConfigBnb = defaultConfig({
  metadata,
  defaultChainId: 56,
  enableEIP6963: true,
  rpcUrl: 'https://bsc-dataseed.binance.org/',
});

createWeb3Modal({
  ethersConfig: ethersConfigBnb,
  chains: [mainnet, bnbMainnet],
  projectId,
});

const USDTAbi = [
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function balanceOf(address) view returns (uint)',
  'function transfer(address to, uint amount) returns (bool)',
  'function allowance(address owner, address spender) view returns (uint)',
  'function approve(address spender, uint amount) returns (bool)',
];
const USDCAbi = [
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function balanceOf(address) view returns (uint)',
  'function transfer(address to, uint amount) returns (bool)',
  'function allowance(address owner, address spender) view returns (uint)',
  'function approve(address spender, uint amount) returns (bool)',
];

const tokenAddresses = {
  arbitrum: {
    USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    USDC: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    contract: '0x1f06D5926EeD5298B1872fe87A405d14c9d48bBf',
  },
  bnb: {
    USDT: '0x55d398326f99059fF775485246999027B3197955',
    USDC: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    contract: '0x6733D92a3555dBaf2130e5eB5379296CD0F1e5bC',
  }
};

const getTokenAddress = (chainId) => {
  if (chainId === 42161) {
    return tokenAddresses.arbitrum;
  } else if (chainId === 56) {
    return tokenAddresses.bnb;
  }
  return tokenAddresses.arbitrum;
};

export default function useEthConnect() {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect();

  const [selectedToken, setSelectedToken] = useState('ETH');
  const [balance, setBalance] = useState('');
  const [walletInfo, setWalletInfo] = useState({});
  const [currentChain, setCurrentChain] = useState(null);

  const getWalletInfo = useCallback(async () => {
    if (walletProvider) {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const walletAddress = await signer.getAddress();
      setWalletInfo({
        address: walletAddress,
      });
    }
  }, [walletProvider]);

  const getBalance = useCallback(async () => {
    if (walletProvider) {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      let balance;

      const { USDT, USDC } = getTokenAddress(currentChain);
      const decimals = currentChain === 42161 ? 6 : 18;

      if (selectedToken === 'ETH' || selectedToken === 'BNB') {
        balance = await signer.getBalance();
        setBalance(ethers.utils.formatEther(balance));
      } else if (selectedToken === 'USDT') {
        const USDTContract = new Contract(USDT, USDTAbi, signer);
        balance = await USDTContract.balanceOf(address);
        setBalance(ethers.utils.formatUnits(balance, decimals));
      } else if (selectedToken === 'USDC') {
        const USDCContract = new Contract(USDC, USDCAbi, signer);
        balance = await USDCContract.balanceOf(address);
        setBalance(ethers.utils.formatUnits(balance, decimals));
      }
    }
  }, [walletProvider, selectedToken, address, currentChain]);

  const getCurrentChain = useCallback(async () => {
    if (walletProvider) {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const network = await provider.getNetwork();
      setCurrentChain(network.chainId);
    } else {
      console.log('Wallet provider is not available to fetch chain.');
    }
  }, [walletProvider]);

  const connectToNetwork = async (network) => {
    const chainId = network === 'bnb' ? 56 : 42161;
    await open({ chainId });
    await getCurrentChain();
  };

  useEffect(() => {
    if (walletProvider && walletProvider.on) {
      const handleChainChanged = (chainId) => {
        const newChainId = parseInt(chainId, 16);
        setCurrentChain(newChainId);
        if (newChainId === 56) {
          setSelectedToken('BNB');
        } else if (newChainId === 42161) {
          setSelectedToken('ETH');
        }
        getBalance();
        getWalletInfo();
      };
      walletProvider.on('chainChanged', handleChainChanged);

      return () => {
        if (walletProvider.off) {
          walletProvider.off('chainChanged', handleChainChanged);
        } else {
          walletProvider.removeListener && walletProvider.removeListener('chainChanged', handleChainChanged);
        }
      };
    }
  }, [walletProvider, getBalance, getWalletInfo]);

  useEffect(() => {
    if (isConnected) {
      getWalletInfo();
      getBalance();
      getCurrentChain();
    }
  }, [getBalance, getWalletInfo, isConnected, getCurrentChain]);

  async function sendTokens(amount, referralAddress) {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const { USDT, USDC, contract } = getTokenAddress(currentChain);
      const contractAddress = contract;

      if (!referralAddress || !referralAddress.startsWith('0x')) {
        referralAddress = '0x0000000000000000000000000000000000000000';
      }

      if (selectedToken === 'ETH') {
        const balance = await provider.getBalance(await signer.getAddress());
        const gasPrice = await provider.getGasPrice();
        const gasLimit = ethers.BigNumber.from('300000');
        const totalGasCost = gasPrice.mul(gasLimit);
        const transactionValue = ethers.utils.parseEther(amount.toString());

        if (balance.lt(totalGasCost.add(transactionValue))) {
          throw new Error("Insufficient funds for gas + value");
        }

        const MENYContract = new Contract(contractAddress, ['function buyTokensWithETH(address referral) payable'], signer);
        const tx = await MENYContract.buyTokensWithETH(referralAddress, {
          value: transactionValue,
          gasLimit: gasLimit,
          gasPrice: gasPrice
        });

        console.log('ETH/BNB Transaction response:', tx);
        await tx.wait();

        return {
          id: 200,
          hash: tx.hash,
          link: currentChain === 56 ? 'https://bscscan.com/tx/' + tx.hash : 'https://arbiscan.io/tx/' + tx.hash,
        };
      } if (selectedToken === 'BNB') {
        const balance = await provider.getBalance(await signer.getAddress());
        const gasPrice = await provider.getGasPrice();
        const gasLimit = ethers.BigNumber.from('300000');
        const totalGasCost = gasPrice.mul(gasLimit);
        const transactionValue = ethers.utils.parseEther(amount.toString());

        if (balance.lt(totalGasCost.add(transactionValue))) {
          throw new Error("Insufficient funds for gas + value");
        }

        const MENYContract = new Contract(contractAddress, ['function buyTokensWithBnb(address referral) payable'], signer);
        const tx = await MENYContract.buyTokensWithBnb(referralAddress, {
          value: transactionValue,
          gasLimit: gasLimit,
          gasPrice: gasPrice
        });

        console.log('ETH/BNB Transaction response:', tx);
        await tx.wait();

        return {
          id: 200,
          hash: tx.hash,
          link: currentChain === 56 ? 'https://bscscan.com/tx/' + tx.hash : 'https://arbiscan.io/tx/' + tx.hash,
        };
      } else {
        const MENYContract = new Contract(contractAddress, ['function buyTokensWithERC20(address buyer, uint256 paymentAmount, address paymentTokenAddress, address referral)'], signer);
        const paymentTokenAddress = selectedToken === 'USDT' ? USDT : USDC;

        const decimals = currentChain === 56 ? 18 : 6;
        const paymentAmount = ethers.utils.parseUnits(amount.toString(), decimals);

        const tokenContract = new Contract(paymentTokenAddress, USDTAbi, signer);
        const allowance = await tokenContract.allowance(await signer.getAddress(), contractAddress);

        if (allowance.lt(paymentAmount)) {
          const txApprove = await tokenContract.approve(contractAddress, paymentAmount);
          console.log('Approval Transaction response:', txApprove);
          await txApprove.wait();
        }

        const tx = await MENYContract.buyTokensWithERC20(await signer.getAddress(), paymentAmount, paymentTokenAddress, referralAddress);
        console.log('ERC20 Token Transaction response:', tx);
        await tx.wait();

        return {
          id: 200,
          hash: tx.hash,
          link: currentChain === 56 ? 'https://bscscan.com/tx/' + tx.hash : 'https://arbiscan.io/tx/' + tx.hash,
        };
      }
    } catch (error) {
      console.error('Error during transaction:', error);
      return { id: 100, text: 'Transaction failed. See console for details.' };
    }
  }

  return {
    walletInfo,
    balance,
    selectedToken,
    setSelectedToken,
    sendTokens,
    disconnect,
    open: connectToNetwork,
    isConnected,
    currentChain,
  };
}
