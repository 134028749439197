export const optionsPayment = [
    // { value: 'sol', label: 'SOL', icon: <img src="/img/input-icons/solana-icon.svg" alt="sol" /> },
    { value: 'eth', label: 'Arbitrum', icon: <img src="https://cryptologos.cc/logos/arbitrum-arb-logo.svg" alt="eth" /> },
    { value: 'bnb', label: 'Binance smart chain', icon: <img src="https://cryptologos.cc/logos/bnb-bnb-logo.svg" alt="bnb" /> },
];

export const optionsCurrencySolana = [
    { value: 'sol', label: 'SOL', icon: <img src="/img/input-icons/solana-icon.svg" alt="sol" /> },
];

export const optionsCurrencyEth = [
    { value: 'eth', label: 'ETH', icon: <img src="/img/input-icons/eth-icon.svg" alt="eth" /> },
    { value: 'usdt', label: 'USDT', icon: <img src="/img/input-icons/usdt-icon.svg" alt="usdt" /> },
    { value: 'usdc', label: 'USDC', icon: <img src="/img/input-icons/usdc-icon.svg" alt="usdc" /> },
];

export const optionsCurrencyBnb = [
    { value: 'bnb', label: 'BNB', icon: <img src="https://cryptologos.cc/logos/bnb-bnb-logo.svg" alt="eth" /> },
    { value: 'usdt', label: 'USDT', icon: <img src="/img/input-icons/usdt-icon.svg" alt="usdt" /> },
    { value: 'usdc', label: 'USDC', icon: <img src="/img/input-icons/usdc-icon.svg" alt="usdc" /> },
];

export const usdtRate = 20000

export const langsList = [
    { value: 'en', label: 'Eng' },
    { value: 'ru', label: 'Рус' },
    { value: 'cz', label: 'Cze' },
    { value: 'nl', label: 'Ned' },
    { value: 'fr', label: 'Fra' },
    { value: 'de', label: 'Deu' },
    { value: 'el', label: 'Ελλ' },
    { value: 'hu', label: 'Mag' },
    { value: 'it', label: 'Ita' },
    { value: 'pl', label: 'Pol' },
    { value: 'pt', label: 'Por' },
    { value: 'ro', label: 'Rom' },
    { value: 'sk', label: 'Svk' },
    { value: 'es', label: 'Esp' },
    { value: 'ar', label: 'عرب' },
    { value: 'zh', label: '简体' },
    { value: 'zhHant', label: '繁體' },
    { value: 'ja', label: '日文' },
    { value: 'ko', label: '한글' },
    { value: 'th', label: 'ไทย' },
    { value: 'id', label: 'Ind' },
    { value: 'tr', label: 'Tur' },
    { value: 'vi', label: 'Vie' }
];

export const timerTarget = '2024-11-13T14:40:00'

export const buttonContractARB = 'https://arbiscan.io/address/0x1f06D5926EeD5298B1872fe87A405d14c9d48bBf'
export const buttonContractBSC = 'https://bscscan.com/address/0x6733D92a3555dBaf2130e5eB5379296CD0F1e5bC'
